<script>
export default {
  name: "ContactForm"
}
</script>

<template>
<div class="kb-contact-form">
  <slot></slot>
  <div class="kbc-form">
    <div class="info-cmpt">
      <h2>
        Přetavíme Vaše sny v realitu
      </h2>
    </div>
    <div class="form-cmpt">
      <form>
        <b-field label="Jméno">
          <b-input type="text"
                   maxlength="30">
          </b-input>
        </b-field>
        <b-field label="Email">
          <b-input type="email"
                   maxlength="30">
          </b-input>
        </b-field>
        <b-field label="Telefonní číslo">
          <b-input type="phone"
                   maxlength="30">
          </b-input>
        </b-field>
        <b-field label="Zpráva">
          <b-input maxlength="200" type="textarea"></b-input>
        </b-field>
        <b-button expanded type="is-primary">Odeslat poptávku</b-button>
      </form>
    </div>
  </div>
</div>
</template>

<style scoped lang="less">
.kb-contact-form {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-repeat: no-repeat;
  margin-top: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/cfback.png);
  background-size: contain;
  background-position: bottom;
  .kbc-info {
    width: 350px;
    height: 350px;
    background-color: #131313;
    padding: 12px;
  }
  .kbc-form {
    width: 800px;
    background-color: #27120D;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      text-align: left;
      font-size: 2.0rem;
      line-height: 2rem;
      color: #D60000;
      font-weight: 900;

    }
    .form-cmpt, .info-cmpt {
      width: 50%;
    }
  }
}
</style>
<style>

.input, .textarea {
  background-color: transparent!important;
  color: white;
}
</style>