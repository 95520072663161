<script>
export default {
  name: "OneReference",
  props: {
    reference: Object
  }
}
</script>

<template>
  <div class="kb-reference">
    <div class="kbr-content" v-html="reference.content"></div>
    <div class="kbr-signature" v-html="reference.name"></div>
  </div>
</template>

<style scoped lang="less">
.kb-reference {
  display: flex;
  width: calc(100% / 4);
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 32px;
  }
  flex-direction: column;
  &::before {
    content: " ";
    background-image: url('../../assets/icons/uvozokvy.svg');
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    text-align: center;
    align-self: center;

  }
  text-align: center;
  .kbr-content {
    padding: 10px;
  }
  .kbr-signature {
    color: #D60000;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
</style>