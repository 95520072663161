<script>
import OneReference from "@/components/Articles/OneReference.vue";

export default {
  name: "ReferencesBlock",
  components: {OneReference},
  data() {
    return {
      references: [
        {
          content: "Bratři z kovářství Buchar nám udělali krásné kované závěsy na dveře našeho starého domečku. Přijeli, všechno hnedka pochopili a za chvíli bylo hotovo. Já i manželka jsme nadšení, jak to vypadá. Doporučujeme všem!",
          name: "Jan a Petra Novákovi"
        },
        {
          content: "Potřebovali jsme novou bránu na naši zahradu a obrátili jsme se na bratry z kovářství Buchar. Výsledek je fakt parádní! Brána je robustní a design přesně podle našich představ. Bratři odvedli skvělou práci a určitě se na ně zase obrátíme.",
          name: "Karel a Martina Horákovi"
        },
        {
          content: "Bucharovi nám svařili kovovou pergolu a musím říct, že jsem z toho nadšený. Pergola je pevná, stabilní a vypadá skvěle. Oceňuju hlavně rychlost a kvalitu práce.",
          name: "Petr Dvořák"
        },
        {
          content: "Chtěli jsme kované zábradlí na schodiště v našem domě a bratři z kovářství Buchar nám vyšli vstříc. Zábradlí je nádherné a perfektně zapadá do interiéru. Bratři jsou opravdoví profíci.",
          name: "Alice a Tomáš Královi"
        },
      ]
    }
  }
}
</script>

<template>
  <div class="kb-references">
    <div class="kbr-title">
      <div class="columns is-center is-mobile  category-head">
        <div class="column">
          <div class="texture"></div>
        </div>
        <div class="column is-narrow">
          <h1 class="title has-text-white">Reference</h1>
        </div>
        <div class="column">
          <div class="texture"></div>
        </div>
      </div>
    </div>
    <div class="kbr-references">
      <one-reference v-for="reference in references" :key="reference.name" :reference="reference"></one-reference>
    </div>
  </div>
</template>

<style scoped lang="less">
.kb-references {
  padding: 36px 40px;

  .kbr-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 32px;
    text-align: center;
  }
  .kbr-references {
    display: flex;
    gap: 12px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}
.texture {
  background-image: url('../../assets/texture.svg');
  height: 40px;
  background-position: center;
}
</style>