import { render, staticRenderFns } from "./Category.vue?vue&type=template&id=5fe88248&scoped=true"
import script from "./Category.vue?vue&type=script&lang=js"
export * from "./Category.vue?vue&type=script&lang=js"
import style0 from "./Category.vue?vue&type=style&index=0&id=5fe88248&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe88248",
  null
  
)

export default component.exports