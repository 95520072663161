<script>
import router from "@/router";

export default {
  name: "InstagramView",
  methods: {
    router() {
      return router
    }
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          image: 'https://kovarstvibuchar.cz/instagram/438720009_705171248245136_5012207031262577065_n.jpg',
          caption: 'Víkendová montáž dvoukřídlé skládací brány a branky 🔥',
          hashtags: ['#handwork #kovarstvibuchar #kovář #kovar #kovanabrana #fabbro #forged #blacksmith #blacksmithing #ceskyvyrobek #czechwork #czechblacksmith #czech'],
          link: 'https://www.instagram.com/p/C51vGVsM0XT/'
        },
        {
          id: 2,
          image: 'https://kovarstvibuchar.cz/instagram/436380189_7559210464135519_4817406106835914598_n.jpg',
          caption: 'V jednoduchosti je krása. 🔥🔥',
          hashtags: ['#rucnivyroba #rucniprace #kovář #kovarstvibuchar #kos #handwork #czechwork #blacksmith #blacksmithing #fabbro #umeleckykovar #czech #ceskyvyrobek'],
          link: 'https://www.instagram.com/p/C5p1101Mgzc/'
        },
        {
          id: 3,
          image: 'https://kovarstvibuchar.cz/instagram/416928444_1083050702885046_3703352448865880373_n.jpg',
          caption: 'Přidáváme pár fotek z montáže ručně kovaného madla, které se odteď táhne po celém schodišti v Římskokatolické farnosti v Lanškrouně, kde působí vážený pan @zibiczendlik, se kterým nám bylo ctí se poznat a spolupracovat.\n' +
              'Děkujeme za tuto příležitost 😊😁',
          hashtags: ['#kovarstvibuchar #kovář #blacksmith #fabbro #forging #rucnivyroba #rucniprace #kovar #handmade #lanskroun'],
          link: 'https://www.instagram.com/p/C1wNQnWscwj/'
        },
        {
          id: 4,
          image: 'https://kovarstvibuchar.cz/instagram/376216318_693149302224871_5147550818581536092_n.jpg',
          caption: 'Opět jsme rádi zavítali na Semilský pecen 🔥❤️\n' +
              'Děkujeme a těšíme se zase za rok 😁\n',
          hashtags: ['#semilskypecen #kovarstvibuchar #blacksmith #trhy #cernyodhlavyazpopaty'],
          link: 'https://www.instagram.com/p/Cw-nwRGMA-h/'
        },
        {
          id: 5,
          image: 'https://kovarstvibuchar.cz/instagram/349222100_781543610306029_9160477040689554798_n.jpg',
          caption: '🔥🔥',
          hashtags: ['#ohniste','#fireplace','#fire','#blacksmithing','#blackshmith','#rucnivyroba','#handmade','#rucniprace','#handwork','#kovar','#kovarstvibuchar','#umeleckykovar','#czechblacksmith'],
          link: 'https://www.instagram.com/p/CswhAFfMEqe/'
        }
      ]
    };
  }
}
</script>

<template>
  <div class="instagram-view">
    <div class="top-bar">
      <a @click="router().push('/instagram')">
        <b-icon icon="instagram" type="is-orange" custom-size="mdi-36px"></b-icon></a>
    </div>
    <div class="instagram-feed">
      <div v-for="post in posts" :key="post.id" class="post">
        <a :href="post.link" target="_blank">
          <img :src="post.image" :alt="post.caption" />
          <div class="">
            <p>{{ post.caption }}</p>
            <p class="hashtags">{{ post.hashtags.join(' ') }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.instagram-view {
  background-color: rgba(44,12,6, .5);
  margin-bottom: 40px;
  padding:  48px;

  .top-bar {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: -12px;
  }

  .instagram-feed {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
  }

  .post {
    width: calc(100% / 6);
  }

  .post img {
    width: 100%;
    height: auto;
  }

  .caption {
    padding: 10px;
    background-color: #f9f9f9;
  }

  .hashtags {
    color: #999;
  }
}
</style>