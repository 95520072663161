<script>
export default {
  name: "ClaimsBlock",
  data() {
    return {
      claims: [
        {
          title: "Rodinná firma",
          content: "Dáváme důraz na tradici a péči. Naše rodinná firma se stará o vaše projekty s láskou a zkušenostmi.",
          icon: ""
        },
        {
          title: "Kalkulace ceny zdarma",
          content: "Nabízíme zdarma kompletní kalkulaci – od zaměření a výkresu až po návrh a realizaci vašeho projektu.",
          icon: ""
        },
        {
          title: "Původ z Českého ráje",
          content: "Naše práce pochází z krásného Českého ráje, kde kvalita a řemeslná zručnost jsou tradicí.",
          icon: ""
        }
      ]
    }
  }
}
</script>

<template>
<div class="claims-block">
  <div class="claims-wrapper">
    <div class="claim" v-for="claim in claims" :key="claim.title">
      <div class="claim-icon">

      </div>
      <div class="claim-text">
        <div class="claim-head">
          {{claim.title}}
        </div>
        <div class="claim-content">
          {{claim.content}}
        </div>
      </div>
    </div>
  </div>


  <div class="claims-bg"></div>
</div>
</template>

<style scoped lang="less">
.claims-block {
  text-align: center;
  position: relative;
  width: 100%;
  .claims-wrapper {
    display: flex;
    justify-content: space-evenly;
    padding: 48px 24px;
    .claim {
      max-width: 300px;
      .claim-text {
        .claim-head {
          color: #D01600;
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
    }
  }
  .claims-bg {
    background-image: url(../assets/texture.svg);
    background-size: cover;
    background-position: center;
    width: 100%;
    filter: grayscale(100%);
    height: 100%;
    position: absolute;
    opacity: .3;
    top:0;
    z-index: -1;
  }
}
</style>