<template>
  <div class="black-container">
    <div class="container is-fluid">
      <b-breadcrumb size="is-small">
        <b-breadcrumb-item tag='router-link' to="/">Kovářství Buchar</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' to="/o-nas" active>{{ $ml.get('KONTAKT') }}</b-breadcrumb-item>
      </b-breadcrumb>
      <section class="content">
        <h1>{{ $ml.get('KONTAKT') }}</h1>
        <contact-form>
          <div class="contact">
            <div class="info">
              <address>
                <b class="name">Stanislav Buchar</b>
                <div><b>IČO</b> 08877637</div>
                <div><b-icon icon="phone" type="is-orange"></b-icon> +420 734 304 528</div>
              </address>
              <br>
              <address>
                <b class="name">Ladislav Buchar</b>
                <div><b>IČO</b> 09450793</div>
                <div><b-icon icon="phone" type="is-orange"></b-icon> +420 739 029 320</div>
              </address>
              <br>
              <address>
                <div>Kněžnice 2, Jičín 506 01</div>
                <div><b-icon icon="email" type="is-orange"></b-icon> info@kovarstvibuchar.cz</div>
              </address>
            </div>
            <l-map style="height: 300px" :zoom="zoom" :center="center">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-marker :lat-lng="markerLatLng">
                <l-icon
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    icon-url="https://kovarstvibuchar.cz/assets/img/pointer.svg" >
                </l-icon>
              </l-marker>

            </l-map>
          </div>
        </contact-form>
      </section>
    </div>
  </div>
</template>
<style scoped lang="less">
.contact {
  display: flex;
  flex-direction: column;
  width: 420px;
  .name {
    color: #D01600
  }
  .info {
    height: 100%;
    background-color: #111;
    padding: 24px;
  }

  .map {
    height: 100%;
  }
}
address {
  text-decoration: none;
  font-style: normal;
}
</style>
<script>
//import axios from "axios";
import ContactForm from "@/components/ContactForm.vue";
import {LMap, LTileLayer, LMarker,LIcon} from 'vue2-leaflet';

export default {
  name: 'veShopTopbar',
  title: "Kovářství Buchar",
  components: {
    ContactForm,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [50.49992, 15.32423],
      markerLatLng: [50.49992, 15.32423],
      iconSize: 64
    }
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor () {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  }


}
</script>