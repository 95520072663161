<template>
    <div class="black-container">
    <div class="container  is-fluid">
        <b-breadcrumb size="is-small">
            <b-breadcrumb-item tag='router-link' to="/">Kovářství Buchar</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/o-nas" active>{{ $ml.get('O_NAS') }}</b-breadcrumb-item>
        </b-breadcrumb>

        <section class="content">

            <h1>O kovářství Buchar</h1>
            <p class="start">
                {{ $ml.get('O_NAS_1') }}
            </p>
            <p>
                {{ $ml.get('O_NAS_2') }}
            </p>
            <p>
                {{ $ml.get('O_NAS_3') }}
            </p>
            <p>
                {{ $ml.get('O_NAS_4') }}
            </p>
            <div class="columns is-vcentered">
                <div class="column"></div>
                <div class="column has-text-left is-narrow" v-for="contact,i in contacts" :key="i">
                    <div class="box is-radiusless is-shadowless has-background-gray">
                        <table class="table">
                            <tr v-for="info, i in contact" :key="i">
                                <th class="has-text-right">{{ $ml.get(info.identifier) }}</th>
                                <td>{{info.value}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>


        </section>
    </div>
        
    </div>
</template>
<style scoped lang="less">
    
    .content {
        background-image: url(https://kovarstvibuchar.cz/assets/img/kovarnistvo.png); 
        background-size: 38%; 
        background-repeat: no-repeat; 
        background-position: bottom -90px left;
        margin-bottom: 12px;

        .start::first-letter {
            initial-letter: 2;
            color: #8A0000;
            font-family: "Old English CE";
        }
    }

    .box {
      background-color: #222;
      color: white;
      table {
        background-color: #222;
        color: white!important;
        * {
          color: white;
          border-color: #871f13;
        }


      }
    }
</style>
<script>
    //import axios from "axios";
    export default {
        name: 'veShopTopbar',
        title: "Kovářství Buchar",
        components: {
        },
        data() {
            return {
                contacts: [
                    [
                        {
                            identifier: 'O_NAS_ICO',
                            value: '09450793'
                        },
                        {
                            identifier: 'O_NAS_OBCHODNI_FIRMA',
                            value: 'Ladislav Buchar'
                        },
                        {
                            identifier: 'O_NAS_PRAVNI_FORMA',
                            value: '101'
                        },
                        {
                            identifier: 'O_NAS_DATUM_VZNIKU',
                            value: '26.8.2020'
                        },
                        {
                            identifier: 'O_NAS_SIDLO',
                            value: '50601 Kněžnice 2'
                        },
                        {
                            identifier: 'O_NAS_ZUJ',
                            value: '573043 - Kněžnice'
                        },
                        {
                            identifier: 'O_NAS_OKRES',
                            value: 'CZ0522 - Jičín'
                        }
                    ], [{
                            identifier: 'O_NAS_ICO',
                            value: '08877637'
                        },
                        {
                            identifier: 'O_NAS_OBCHODNI_FIRMA',
                            value: 'Stanislav Buchar'
                        },
                        {
                            identifier: 'O_NAS_PRAVNI_FORMA',
                            value: '101'
                        },
                        {
                            identifier: 'O_NAS_DATUM_VZNIKU',
                            value: '22.1.2020'
                        },
                        {
                            identifier: 'O_NAS_SIDLO',
                            value: '50601 Kněžnice 2'
                        },
                        {
                            identifier: 'O_NAS_ZUJ',
                            value: '573043 - Kněžnice'
                        },
                        {
                            identifier: 'O_NAS_OKRES',
                            value: 'CZ0522 - Jičín'
                        }
                    ]
                ]
            }
        },
        mounted() {
        this.$Progress.finish();
        }


    }
</script>